<template>
 
 <GeneralHeroSearch /> 
 
  <div class="pb index_page grid_width">
    <!-- <UiInfinityScroll/> -->


    <template v-if="!feedData?.items_tag">
      <SkFeedTag />
    </template>
    <template v-else>
      <FeedTags 
        :list="feedData?.items_tag" 
        :selected="selectedFilter" 
        @choose="handleChooseTag" 
      />
    </template>
  

    <template v-if="feedDataStatus === 'loading' && !feedData?.items_feed?.length">
      <SkFeed />
      <SkFeed />
      <SkFeed />
      <SkFeed /> 
    </template>
    <template v-else>

      
      <div v-if="feedData?.items_feed" class="feed_items">
        <div v-for="(feed, feed_key) in feedData?.items_feed" :key="feed_key" :data="feed" class="row_item"
          :data-type-id="feed.id">

          
          <div class="header" v-if="feed.lng_key || feed.name">

            <template
              v-if="Array('seed', 'brandseed', 'tent', 'brandtent', 'nutrient', 'brandnutrient', 'lamp', 'brandlamp', 'user').indexOf(feed.type) >= 0">
              <div class="ic"><img loading="lazy" :alt="feed.lng_key ? $t(feed.lng_key) : feed.name" :src="feed.avatar"></div>
            </template>
            <template v-else-if="feed.type == 'mass_harvests'">
              <div class="ic"><img loading="lazy" :alt="feed.name" src="/images/setup/setup_icons-18.svg"></div>
            </template>
            <template v-else-if="feed.type == 'shorts'">
              <i class="icon-shorts"></i>
            </template>
            <template v-else-if="feed.type">
              <!-- <div class="ic"><i :class="'icon-feed-' + feed.type.replace('_','-').replace('_','-').replace('_','-')"></i></div>             -->
            </template>

            <a class="name" :href="feed.link" onclick="FeedManager.click('locale_ru','1')">{{ feed.lng_key ?
              $t(feed.lng_key) : feed.name }}</a>

            <div class="hint">{{ feed.hint ? $t(feed.hint) : '' }}</div>
            <div class="hidden_block hidden" onclick="FeedManager.hideGroup('locale_ru')">
              <i class="icon remove"></i>
            </div>
          </div>


          <template v-if="feed.items_list.items_diary?.length">
            <UiHorizontalScroll
              :arrows="true"
              :arrows-show-hover="true"
              :platform="'mobile'"
              >
              <GeneralShowMoreFeed 
                :platform="feed.items_list.items_diary.length > 8 && feed.id == 'recommended' ? 'desktop' : 'none'"
                :max-height="feed.id == 'recommended' ? 220*2 : 220"
                >
                <div class="report_boxs horizontal_mobile data">
                  <GeneralDiary v-for="(diary, diary_key) in feed.items_list.items_diary" :key="diary_key" :data="diary">
                  </GeneralDiary>
                </div>
              </GeneralShowMoreFeed>
            </UiHorizontalScroll>
          </template>
 

          <UiHorizontalScroll
          v-else-if="feed.items_list.items_grow_question?.length"
              :arrows="true"
              :arrows-show-hover="true"
              :platform="'mobile'"
              >
            <div               
              class="questionlist">
              <QuestionBox 
                v-for="(opt, ind) in feed.items_list.items_grow_question.slice(0, 4)" 
                :key="ind" 
                :question="opt"
              />
            </div>
          </UiHorizontalScroll>

          <div 
            v-else-if="feed.items_list.items_blog?.length"
            class="bloglist">
            <BlogBox v-for="(opt, ind) in feed.items_list.items_blog" 
              :key="ind"
              :data="opt" 
              />
          </div>


          <UiHorizontalScroll
              v-else-if="feed.items_list.items_strain?.length"
              :arrows="false"
              :arrows-show-hover="false"
              >
              <div             
              class="strainslist">

            <GeneralStrain
              v-for="(item, num) in feed.items_list.items_strain"        
              :cover-s="item.cover_s"
              :link="item.link"
              :nm="item.name"
              :thc="item.props?.seed_strain_thc"
              :cbd="item.props?.seed_strain_cbd"
              :item-stat="item.item_stat"
              :cnt-diaries="item.item_stat?.cnt_reports"
              :cnt-growers="item.item_stat?.cnt_growers"
              :cnt-harvests="item.item_stat?.cnt_harvests"
              :rate-norm="item.item_stat?.avg_rate"
              :rate="item.item_stat?.avg_rate"
            />
            </div>
          </UiHorizontalScroll>
 

          <UiHorizontalScroll
            v-else-if="feed.items_list.items_photo?.length"
            :arrows="false"
            :arrows-show-hover="false"
            >
            <div             
              class="photoslist">

            <GeneralMeme
              v-for="(item, num) in feed.items_list.items_photo"        
              :link="item.item_giveaway.link"
              :size-m="item.size_m"
              :size-big="item.size_big"
              :author-link="item.item_user.link"
              :author-name="item.item_user.name"
            />
            </div>
          </UiHorizontalScroll>
 

          <div 
            v-else-if="feed.items_list.items_open_contest?.length"
            class="contestlist"> 
            <GiveawayBoxCompact v-for="(opt, ind) in feed.items_list.items_open_contest" 
              :key="ind"
              :name="opt.short_name" 
              :link="opt.link" 
              :cover="opt.cover_back_small" 
              />
          </div>

          <FeedVideos v-else-if="feed.items_list.items_video?.length" 
            :list="feed.items_list.items_video" 
            /> 



        </div>

      </div>



    </template>

    <ClientOnly>        
      <UiInfinityScroll 
        v-if="isEnded === false"
        :is-loading="feedDataStatus === 'loading'"
        ref="elInfinityScroll"/>
    </ClientOnly>

  </div>
</template>


<script setup lang="ts">

import type { Feed, FeedTag }  from "@/types/other"

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { $api, $ga, $adv, hook } = useNuxtApp()


const start = ref<number>(0)
const limit = ref<number>(6)
const isEnded = ref<boolean>(false)
const elInfinityScroll = ref(null)
const selectedFilter = ref<string>('all')


const handleChooseTag = (tag: FeedTag) => {
  start.value = 0;
  feedData.value.items_feed = null;
  selectedFilter.value = tag.filter;
  $ga.clickFeedTag(tag.filter);
}



const loadedGroups = computed(() => {
  if(start.value == 0){
    return [];
  }else{
    return (feedData?.value?.items_feed.map((item: Feed) => item.id) ?? []).join(',');
  }
})
  

const loadData = async function() { 
  const response = await $api.getFeed({
    start: start.value,
    limit: limit.value,
    loaded: loadedGroups.value,
    filter: selectedFilter.value
  })  
  return response;  
}


const { status: feedDataStatus,  data: feedData, refresh: refreshData } = await useLazyAsyncData('feedData', async () => await loadData())




watchArray([start, selectedFilter], async ([new_start, new_selectedFilter], added, removed) => {

  if(new_start === 0){
    feedData.value.items_feed = [];
    isEnded.value = false;
  } 
  
  feedDataStatus.value = 'loading';

  var dt = await loadData();

  if(dt.items_feed?.length == 0)
    isEnded.value = true;  


  if(feedData.value?.items_feed)
    feedData.value.items_feed = [...feedData.value.items_feed, ...dt.items_feed]

  feedDataStatus.value = 'success';


}, {deep: true})





useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(feedDataStatus.value !== 'loading' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


useHead(useNuxtApp().$head.getFeed());


onUnmounted(() => {
  feedData.value = null;
})


</script>






<style scoped>
.index_page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hidden_feedData {
  display: none;
}

.feed_items {}

.feed_items .row_item {
  margin-bottom: 5px;
}

.feed_items .row_item>.header {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.feed_items .row_item>.header .ui.button {
  padding: 7px 20px;
}

.feed_items .row_item .header>.ic {
  display: flex;
  width: 42px;
  aspect-ratio: 1/1;
  align-items: center;
  justify-content: center;
}

.feed_items .row_item .header>.ic img {
  width: 100%;
  border-radius: 3px;
}

.feed_items .row_item .header>.ic img.user {
  border-radius: 100px;
}

.feed_items .row_item .header>.ic .icon_custom {
  font-size: 1.5rem;
}

.feed_items .row_item .header>.ic i {
  font-size: 1.2rem;
  width: 24px;
  height: 24px;
}

.feed_items .row_item .header>.ic .icon-trophy {
  color: #f6c70b;
}

.feed_items .row_item .header>.name {
  font-weight: bold;
  font-size: 1.1rem;
  display: inline-block;
}

.feed_items .row_item .header>.hint {
  color: gray;
  margin-top: 3px;
}

.feed_items .row_item .header>.hidden_block {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 2px;
  color: gray;
  cursor: pointer;
}

.feed_items .touch_slider .shadow_right,
.feed_items .touch_slider .shadow_left {
  top: 30px;
}

.feed_items .touch_slider_scroll {
  text-align: left;
}


.ui.loading.button {
  width: calc(100% - 20px);
}

@container pb (max-width: 768px) {
  .feed_items .row_item {
    /* margin-bottom: 1rem; */
  }
}

/* SHOW MORE*/

.show_more {
  width: 100%;
}

.show_more .show_more_content {
  max-height: 213px;
  overflow: hidden;
  flex-flow: wrap;
  margin-right: -20px;
  width: 100%;
  display: flex;
}

[data-type-id="recommended"] .show_more .show_more_content {
  max-height: 442px;
}

.show_more.active .show_more_content {
  max-height: 100%;
  overflow: hidden;
}

.show_more .show_more_btns {
  display: block;
  color: #1f1f1f;
  text-align: right;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 12px;
  cursor: pointer;
}

.recommended .show_more.enable .show_more_btns,
.show_more.enable:hover .show_more_btns {
  opacity: 1;
}

.show_more .show_more_btns>.more_item {
  display: none;
  background-color: white;
  padding: 5px 23px 5px 10px;
  border-radius: 3px;
}

.show_more .show_more_btns .btn_more {
  display: inline-block;
}

.show_more.active .show_more_btns .btn_less {
  display: inline-block;
}

.follow_btn {
  position: absolute;
  right: 40px;

}

/* MOBILE */


@media (max-width: 648px) {
  .show_more .show_more_content {
    max-height: 190px;
  }

  .show_more.active .show_more_content {
    max-height: 100%;
  }
}


@media (max-width: 500px) {
  .show_more .show_more_content {
    max-height: 220px;
  }

  .feed_items .row_item .header>.hint {
    display: none;
  }
}


@media (max-width: 400px) {
  .show_more .show_more_content {
    max-height: 193px;
  }

  .show_more.active .show_more_content {
    max-height: 100%;
  }
}


@media (max-width: 360px) {
  .show_more .show_more_content {
    max-height: 372px;
  }
}

@media (max-width: 310px) {
  .show_more .show_more_content {
    max-height: 189px;
  }
}






@media only screen and (max-width: 648px) {
  .recommended .show_more .show_more_content {
    max-height: 408px;
  }
}


@media only screen and (max-width: 600px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }
}

@media only screen and (max-width: 530px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }
}

@media only screen and (max-width: 500px) {
  .recommended .show_more .show_more_content {
    max-height: 454px;
  }
}

@media only screen and (max-width: 400px) {
  .recommended .show_more .show_more_content {
    max-height: 395px;
  }

}





/* contest */

.contestlist {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  flex-wrap: wrap;
  height: 290px;
  justify-content: left;
}

@container pb (max-width: 768px) {
  .contestlist {
    flex-wrap: nowrap;
  }
}

/* strains list */

.strainslist{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: left;
  gap: 0.5rem;
  width: 100%;
}

/* strains list */

.photoslist{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: left;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
}


.strainslist:deep(.seed){
  /* width: 120px; */
  /* flex-shrink: 0; */
  /* min-width: 177px; */
}


.strainslist:deep(.seed):nth-child(n+8){
  display: none;
}
@container pb (max-width: 1100px) {
  .strainslist:deep(.seed):nth-child(n+7){
    display: none;
  } 
}
@container pb (max-width: 650px) {
  .strainslist:deep(.seed){
    display: block;
    width: 160px;
    flex-shrink: 0;
    margin-right: 0rem;
  } 
}

/* questionlist */

.questionlist {
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.questionlist:deep(.question_box){
  max-width: calc(25% - 0.5rem);
}
.questionlist:deep(.question_box .data .ttl){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.questionlist:deep(.question_box .data){
  height: 100%;
}
.questionlist:deep(.question_box .glr){  
  margin: auto;
  margin-top: 10px;
}

@container pb (max-width: 956px) {

  .questionlist:deep(.question_box ){
    max-width: calc(33% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+4)) {
    display: none;
  }

}

@container pb (max-width: 756px) {

  .questionlist:deep(.question_box ){
    max-width: calc(50% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+4)) {
    display: none;
  }

}

@container pb (max-width: 520px) {

  .questionlist {
    flex-wrap: nowrap;
  } 
  .questionlist:deep(.question_box ){
    max-width: calc(70% - 0.5rem);
  }
  .questionlist:deep(.question_box:nth-child(n+3)) {
    display: inline-block;
  }

  /* .questionlist {}
  .questionlist > div,
  .questionlist .question_box {
    max-width: 100%;
    width: 100%;
  } */
}


/* blogs */

.bloglist{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 1.5rem;
}

.bloglist:deep(.jbox){
    width: calc(100% / 3 - 1.5rem);
  }

@container pb (max-width: 956px) {

  .bloglist:deep(.jbox){
    width: calc(100% / 2 - 1.5rem);
  }
  .bloglist:deep(.jbox:nth-child(n+3)) {
    display: none;
  }

}

@container pb (max-width: 700px) {

  .bloglist{
    flex-wrap: wrap;
  }
  .bloglist:deep(.jbox){
    width: 100%;
  }

}


</style>
